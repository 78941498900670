<script setup>
  const props = defineProps({
    color: { type: String },
    size: { type: String, default: '3x' },
    faStyle: { type: String, default: 'solid' }
  })

  const faStyle = computed(() => `fa-${props.faStyle}`)
</script>

<template>
  <div class="flex items-center justify-center">
    <FontAwesomeIcon
      class="text-viking-400"
      :icon="[
        faStyle,
        'circle-notch'
      ]"
      :size="props.size"
      spin
      :style="{ color }"
    />
  </div>
</template>
